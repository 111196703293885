// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-about-us-page-template-about-us-page-template-js": () => import("./../src/templates/AboutUsPageTemplate/AboutUsPageTemplate.js" /* webpackChunkName: "component---src-templates-about-us-page-template-about-us-page-template-js" */),
  "component---src-templates-catalysing-page-template-catalysing-page-template-js": () => import("./../src/templates/CatalysingPageTemplate/CatalysingPageTemplate.js" /* webpackChunkName: "component---src-templates-catalysing-page-template-catalysing-page-template-js" */),
  "component---src-templates-contact-page-template-contact-page-template-js": () => import("./../src/templates/ContactPageTemplate/ContactPageTemplate.js" /* webpackChunkName: "component---src-templates-contact-page-template-contact-page-template-js" */),
  "component---src-templates-convening-page-template-convening-page-template-js": () => import("./../src/templates/ConveningPageTemplate/ConveningPageTemplate.js" /* webpackChunkName: "component---src-templates-convening-page-template-convening-page-template-js" */),
  "component---src-templates-course-category-template-course-category-template-js": () => import("./../src/templates/CourseCategoryTemplate/CourseCategoryTemplate.js" /* webpackChunkName: "component---src-templates-course-category-template-course-category-template-js" */),
  "component---src-templates-course-page-template-course-page-template-js": () => import("./../src/templates/CoursePageTemplate/CoursePageTemplate.js" /* webpackChunkName: "component---src-templates-course-page-template-course-page-template-js" */),
  "component---src-templates-educating-page-template-educating-page-template-js": () => import("./../src/templates/EducatingPageTemplate/EducatingPageTemplate.js" /* webpackChunkName: "component---src-templates-educating-page-template-educating-page-template-js" */),
  "component---src-templates-event-page-template-event-page-template-js": () => import("./../src/templates/EventPageTemplate/EventPageTemplate.js" /* webpackChunkName: "component---src-templates-event-page-template-event-page-template-js" */),
  "component---src-templates-index-page-template-index-page-template-js": () => import("./../src/templates/IndexPageTemplate/IndexPageTemplate.js" /* webpackChunkName: "component---src-templates-index-page-template-index-page-template-js" */),
  "component---src-templates-kitchen-sink-kitchen-sink-js": () => import("./../src/templates/KitchenSink/KitchenSink.js" /* webpackChunkName: "component---src-templates-kitchen-sink-kitchen-sink-js" */),
  "component---src-templates-our-partners-template-our-partners-template-js": () => import("./../src/templates/OurPartnersTemplate/OurPartnersTemplate.js" /* webpackChunkName: "component---src-templates-our-partners-template-our-partners-template-js" */),
  "component---src-templates-our-resources-template-our-resources-template-js": () => import("./../src/templates/OurResourcesTemplate/OurResourcesTemplate.js" /* webpackChunkName: "component---src-templates-our-resources-template-our-resources-template-js" */),
  "component---src-templates-past-events-page-template-past-events-page-template-js": () => import("./../src/templates/PastEventsPageTemplate/PastEventsPageTemplate.js" /* webpackChunkName: "component---src-templates-past-events-page-template-past-events-page-template-js" */),
  "component---src-templates-portfolio-template-portfolio-template-js": () => import("./../src/templates/PortfolioTemplate/PortfolioTemplate.js" /* webpackChunkName: "component---src-templates-portfolio-template-portfolio-template-js" */),
  "component---src-templates-portfolios-page-template-portfolios-page-template-js": () => import("./../src/templates/PortfoliosPageTemplate/PortfoliosPageTemplate.js" /* webpackChunkName: "component---src-templates-portfolios-page-template-portfolios-page-template-js" */),
  "component---src-templates-press-office-template-press-office-template-js": () => import("./../src/templates/PressOfficeTemplate/PressOfficeTemplate.js" /* webpackChunkName: "component---src-templates-press-office-template-press-office-template-js" */),
  "component---src-templates-press-release-categories-template-press-release-categories-template-js": () => import("./../src/templates/PressReleaseCategoriesTemplate/PressReleaseCategoriesTemplate.js" /* webpackChunkName: "component---src-templates-press-release-categories-template-press-release-categories-template-js" */),
  "component---src-templates-press-release-template-press-release-template-js": () => import("./../src/templates/PressReleaseTemplate/PressReleaseTemplate.js" /* webpackChunkName: "component---src-templates-press-release-template-press-release-template-js" */),
  "component---src-templates-project-category-template-project-category-template-js": () => import("./../src/templates/ProjectCategoryTemplate/ProjectCategoryTemplate.js" /* webpackChunkName: "component---src-templates-project-category-template-project-category-template-js" */),
  "component---src-templates-project-page-template-project-page-template-js": () => import("./../src/templates/ProjectPageTemplate/ProjectPageTemplate.js" /* webpackChunkName: "component---src-templates-project-page-template-project-page-template-js" */),
  "component---src-templates-publication-category-template-publication-category-template-js": () => import("./../src/templates/PublicationCategoryTemplate/PublicationCategoryTemplate.js" /* webpackChunkName: "component---src-templates-publication-category-template-publication-category-template-js" */),
  "component---src-templates-publication-template-publication-template-js": () => import("./../src/templates/PublicationTemplate/PublicationTemplate.js" /* webpackChunkName: "component---src-templates-publication-template-publication-template-js" */),
  "component---src-templates-researching-page-template-researching-page-template-js": () => import("./../src/templates/ResearchingPageTemplate/ResearchingPageTemplate.js" /* webpackChunkName: "component---src-templates-researching-page-template-researching-page-template-js" */),
  "component---src-templates-scholar-page-template-scholar-page-template-js": () => import("./../src/templates/ScholarPageTemplate/ScholarPageTemplate.js" /* webpackChunkName: "component---src-templates-scholar-page-template-scholar-page-template-js" */),
  "component---src-templates-team-page-template-team-page-template-js": () => import("./../src/templates/TeamPageTemplate/TeamPageTemplate.js" /* webpackChunkName: "component---src-templates-team-page-template-team-page-template-js" */),
  "component---src-templates-team-profile-template-team-profile-template-js": () => import("./../src/templates/TeamProfileTemplate/TeamProfileTemplate.js" /* webpackChunkName: "component---src-templates-team-profile-template-team-profile-template-js" */)
}

