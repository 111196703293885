module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bertha Centre","short_name":"Bertha Centre","start_url":"/","background_color":"#8DC63F","theme_color":"#8DC63F","display":"standalone","icon":"src/img/favicon.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"f36868549391146357af7d3252eafdfe"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"1222774591393889"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-173222017-1","head":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/Users/duanecilliers/Projects/freelance/bertha-centre-website/src/cms/cms.js","enableIdentityWidget":true,"manualInit":true,"publicPath":"admin","htmlTitle":"Content Manager"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
